
import { Options, Vue } from 'vue-class-component'
import * as echarts from 'echarts'

import { gcj02ToWgs84 } from "@/utils/tool";

import { useCache } from '@/hooks/web/useCache'
import { alarmHandleRecordApi, alarmStatusApi, monthAlarmAnaliseApi } from '@/api/alarm'
import {deviceIncreaseApi, deviceListApi, deviceStatusApi, getAlarmDevicesApi, signalLevelApi, getOfflineDevicesApi, getBrokenDevicesApi, getMediaUrlApi} from '@/api/device'
import { getAlarmLogApi, loadLogApi, loadTodayAlarmApi, receiveAlarmApi, verifyAlarmApi } from '@/api/logs'
import XYTree from '@/components/widgets/XYTree.vue'
import EnergyAnalyse from "@/components/elec/EnergyAnalyse.vue";
import EnergyManage from "@/components/elec/EnergyManage.vue";
import DeviceStatus from "@/components/elec/DeviceStatus.vue";
import UserInfo from "@/components/elec/UserInfo.vue";
import ParamSetting from "@/components/elec/ParamSetting.vue";
import RepairReport from "@/components/elec/RepairReport.vue";
import CameraMain from "@/components/camera/CameraMain.vue";
import SystemSetting from "@/components/setting/SystemSetting.vue";
import ChargeStationDeviceList from "@/components/ChargeStation/ChargeStationDeviceList.vue";
import {checkLoginApi} from "@/api/auth";
import {getDevicesApi} from "@/api/elec";
import {switchZoneApi} from "@/api/alarmUser";

interface Tree {
  [key: string]: any
}

let webrtcPlayer: any = null;

@Options({
  components: {
    CameraMain, ChargeStationDeviceList,
    RepairReport, ParamSetting, UserInfo, DeviceStatus, EnergyManage, EnergyAnalyse, SystemSetting,
    XYTree
  },
  data () {
    return {
      wsCache: null,
      name: '',
      seatName: '',
      token: '',
      adminId: '0',
      isPolice: '0',
      isManager: '0',
      adminType: '0',
      timeInterval: null,
      timeStr: '',
      timer: null,
      expandAll: false,
      deviceSwipeInterval: null,
      mouseEnter: false,
      mouseDown: false,
      initPointX: 0,
      initPointY: 0,
      initIndex: 0,
      wheelCount: 0,
      showCameraBox: false,
      checkedDeviceTypes: [
        'shop_alarm', 'lock_alarm', 'fire_alarm', 'union_alarm', 'gas_alarm',
        'elec_alarm', 'access_alarm', 'water_alarm', 'emer_alarm', 'camera_alarm', 'charge_station', 'lift_alarm'
      ],
      contextMenuCovered: false,
      contextMenuShow: false,
      contextMenus: [],
      contextMenuNode: null,
      saveCameraDevice: null,
      showCheckDeviceTypeBox: false,
      showDeviceTree: false,
      showAlarmInfoTable: true,
      showOfflineDeviceTable: false,
      showBrokenDeviceTable: false,
      showTodayAlarmTable: false,
      selectedDeviceType: '',
      filterText: '',
      tempDeviceData: [],
      devicesData: [],
      usersData: [],
      innerWidth: 0,
      innerHeight: 0,
      alarmAudioSrc: require('../assets/audios/alarm.mp3'),
      alarmStatus: {
        alarmCnt: 0,
        untreatedCnt: 0,
        handlingCnt: 0,
        finishedCnt: 0
      },
      deviceStatus: {
        totalCnt: 0,
        onlineCnt: 0,
        offlineCnt: 0,
        brokenCnt: 0,
        alarmCnt: 0
      },
      cameraDevice: {},
      alarmInfoData: [],
      todayAlarmData: [],
      offlineDeviceData: [],
      brokenDeviceData: [],
      allDeviceTypes: [
        {
          deviceType: 'shop_alarm',
          deviceName: '店铺报警系统',
          normalImg: require('../assets/images/txt-shop.png'),
          selectedImg: require('../assets/images/txt-shop-s.png')
        },
        {
          deviceType: 'lock_alarm',
          deviceName: '智能门锁系统',
          normalImg: require('../assets/images/txt-lock.png'),
          selectedImg: require('../assets/images/txt-lock-s.png')
        },
        {
          deviceType: 'fire_alarm',
          deviceName: '智慧烟感系统',
          normalImg: require('../assets/images/txt-fire.png'),
          selectedImg: require('../assets/images/txt-fire-s.png')
        },
        {
          deviceType: 'union_alarm',
          deviceName: '火情联防系统',
          normalImg: require('../assets/images/txt-union.png'),
          selectedImg: require('../assets/images/txt-union-s.png')
        },
        {
          deviceType: 'elec_alarm',
          deviceName: '智慧用电系统',
          normalImg: require('../assets/images/txt-elec.png'),
          selectedImg: require('../assets/images/txt-elec-s.png')
        },
        {
          deviceType: 'gas_alarm',
          deviceName: '智慧气感系统',
          normalImg: require('../assets/images/txt-gas.png'),
          selectedImg: require('../assets/images/txt-gas-s.png')
        },
        {
          deviceType: 'access_alarm',
          deviceName: '租房门控系统',
          normalImg: require('../assets/images/txt-access.png'),
          selectedImg: require('../assets/images/txt-access-s.png')
        },
        {
          deviceType: 'water_alarm',
          deviceName: '智慧水浸系统',
          normalImg: require('../assets/images/txt-water.png'),
          selectedImg: require('../assets/images/txt-water-s.png')
        },
        {
          deviceType: 'emer_alarm',
          deviceName: '紧急求助系统',
          normalImg: require('../assets/images/txt-emer.png'),
          selectedImg: require('../assets/images/txt-emer-s.png')
        },
        {
          deviceType: 'camera_alarm',
          deviceName: '智慧视频系统',
          normalImg: require('../assets/images/txt-camera.png'),
          selectedImg: require('../assets/images/txt-camera-s.png')
        },
        {
          deviceType: 'charge_station',
          deviceName: '智慧充电系统',
          normalImg: require('../assets/images/txt-charge.png'),
          selectedImg: require('../assets/images/txt-charge-s.png')
        },
        {
          deviceType: 'lift_alarm',
          deviceName: '电梯安全系统',
          normalImg: require('../assets/images/txt-elesafe.png'),
          selectedImg: require('../assets/images/txt-elesafe-s.png')
        },
      ],
      deviceTypes: [
        {
          deviceType: 'shop_alarm',
          deviceName: '店铺报警系统',
          normalImg: require('../assets/images/txt-shop.png'),
          selectedImg: require('../assets/images/txt-shop-s.png')
        },
        {
          deviceType: 'lock_alarm',
          deviceName: '智能门锁系统',
          normalImg: require('../assets/images/txt-lock.png'),
          selectedImg: require('../assets/images/txt-lock-s.png')
        },
        {
          deviceType: 'fire_alarm',
          deviceName: '智慧烟感系统',
          normalImg: require('../assets/images/txt-fire.png'),
          selectedImg: require('../assets/images/txt-fire-s.png')
        },
        {
          deviceType: 'union_alarm',
          deviceName: '火情联防系统',
          normalImg: require('../assets/images/txt-union.png'),
          selectedImg: require('../assets/images/txt-union-s.png')
        },
        {
          deviceType: 'elec_alarm',
          deviceName: '智慧用电系统',
          normalImg: require('../assets/images/txt-elec.png'),
          selectedImg: require('../assets/images/txt-elec-s.png')
        },
        {
          deviceType: 'gas_alarm',
          deviceName: '智慧气感系统',
          normalImg: require('../assets/images/txt-gas.png'),
          selectedImg: require('../assets/images/txt-gas-s.png')
        },
        {
          deviceType: 'access_alarm',
          deviceName: '租房门控系统',
          normalImg: require('../assets/images/txt-access.png'),
          selectedImg: require('../assets/images/txt-access-s.png')
        },
        {
          deviceType: 'water_alarm',
          deviceName: '智慧水浸系统',
          normalImg: require('../assets/images/txt-water.png'),
          selectedImg: require('../assets/images/txt-water-s.png')
        },
        {
          deviceType: 'emer_alarm',
          deviceName: '紧急求助系统',
          normalImg: require('../assets/images/txt-emer.png'),
          selectedImg: require('../assets/images/txt-emer-s.png')
        },
        {
          deviceType: 'camera_alarm',
          deviceName: '智慧视频系统',
          normalImg: require('../assets/images/txt-camera.png'),
          selectedImg: require('../assets/images/txt-camera-s.png')
        },
        {
          deviceType: 'charge_station',
          deviceName: '智慧充电系统',
          normalImg: require('../assets/images/txt-charge.png'),
          selectedImg: require('../assets/images/txt-charge-s.png')
        },
        {
          deviceType: 'lift_alarm',
          deviceName: '电梯安全系统',
          normalImg: require('../assets/images/txt-elesafe.png'),
          selectedImg: require('../assets/images/txt-elesafe-s.png')
        },
        {
          deviceType: 'shop_alarm',
          deviceName: '店铺报警系统',
          normalImg: require('../assets/images/txt-shop.png'),
          selectedImg: require('../assets/images/txt-shop-s.png')
        },
        {
          deviceType: 'lock_alarm',
          deviceName: '智能门锁系统',
          normalImg: require('../assets/images/txt-lock.png'),
          selectedImg: require('../assets/images/txt-lock-s.png')
        },
        {
          deviceType: 'fire_alarm',
          deviceName: '智慧烟感系统',
          normalImg: require('../assets/images/txt-fire.png'),
          selectedImg: require('../assets/images/txt-fire-s.png')
        },
        {
          deviceType: 'union_alarm',
          deviceName: '火情联防系统',
          normalImg: require('../assets/images/txt-union.png'),
          selectedImg: require('../assets/images/txt-union-s.png')
        },
      ],
      currentDeviceIndex: -1,
      showDeviceIndex: 0,
      selectedMonth: '',
      selectedUser: {
      },
      showSelectedUser: false,
      showAlarmInfo: false,
      handlingLog: {},
      handlingStatus: false,
      showHistoryLog: false,
      historyLog: {},
      id: 'cluster',
      enableDefaultStyle: true,
      minimumClusterSize: 2,
      zoomOnClick: true,
      gridSize: 3,
      averageCenter: false,
      maxZoom: 14,
      center: { lat: 40.115935, lng: 124.362676 },
      geometries: [],
      ws: null,
      wsInterval: null,
      alarmFormRules: {
        verifyMethod: [{
          required: true,
          message: '请选择核警方式',
          trigger: 'change'
        }],
        verifyRecord: [{
          required: true,
          message: '请输入备注内容',
          trigger: 'blur'
        }]
      },
      alarmFormData: {
        verifyMethod: '',
        verifyRecord: '',
        handleRecord: ''
      },
      historyAlarmFormData: {
        verifyMethod: '',
        verifyRecord: '',
        handleRecord: ''
      },
      activeTab: 'map',
      showDeviceType: 'all',
      page: 1,
      total: 0,
      lastPage: 0,
      perPage: 0,
      devices: [],
      pageNum: 0,
      refreshInterval: null,
      checkLoginInterval: null,
      activeItem: {
        host_no: '',
        device_no: '',
        device_type: ''
      },
      offlineDevicePage: 1,
      offlineDeviceLastPage: -1,
      brokenDevicePage: 1,
      brokenDeviceLastPage: -1,
      todayAlarmPage: 1,
      todayAlarmLastPage: -1,
      alarmPage: 1,
      alarmLastPage: -1,
      alarmType: '',
      monthAlarmAnaliseTimeout: null,
      deviceIncreaseTimeout: null,
      signalLevelTimeout: null,
      monthAlarmTimeout: null,
      TMap: null,
      markers: [],
      manualOpen: false,
      contextEvent: null,
      selectedChargeStation: null,
      checkedDeviceCovered: false
    }
  },
  mounted () {
    const self = this

    const { wsCache } = useCache()

    this.wsCache = wsCache

    const ele = document.getElementById('app');

    if (ele !== null) {
      ele.requestFullscreen();

      this.wsCache.set('is_fullscreen', true)
    }

    this.name = wsCache.get('name')
    this.seatName = wsCache.get('seat_name')
    this.token = wsCache.get('token')
    this.adminId = wsCache.get('admin_id').toString()
    this.isPolice = wsCache.get('is_police').toString()
    this.isManager = wsCache.get('is_manager')
    this.adminType = wsCache.get('type')

    let latitude = wsCache.get('latitude')
    let longitude = wsCache.get('longitude')

    if (latitude === null || latitude === "" || longitude === null || longitude === "") {
      latitude = 40.115935;
      longitude = 124.362676;
    }

    var imageURL = "http://t0.tianditu.gov.cn/img_w/wmts?" +
        "SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles" +
        "&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=d20b5e9e14aef6075f405c99c1d6d34a";
    //创建自定义图层对象
    // eslint-disable-next-line no-undef
    let lay = new T.TileLayer(imageURL, {minZoom: 1, maxZoom: 18});
    let config = {layers: [lay]};

    // eslint-disable-next-line no-undef
    self.TMap = new T.Map("map");

    // eslint-disable-next-line no-undef
    self.TMap.centerAndZoom(new T.LngLat(longitude, latitude), 13);

    self.loadDeviceList()

    if (this.timeInterval !== null) {
      clearInterval(this.timeInterval)
    }

    const savedCheckedDevicetypes = wsCache.get('checked_device_types')

    if (savedCheckedDevicetypes !== null && savedCheckedDevicetypes !== '') {
      this.checkedDeviceTypes = JSON.parse(savedCheckedDevicetypes)

      this.setDeviceTypes()
    }

    this.initMonth()
    this.setTimeStr()
    this.timeInterval = setInterval(this.setTimeStr, 1000)

    if (this.deviceSwipeInterval !== null) {
      clearInterval(this.deviceSwipeInterval)
    }

    this.deviceSwipeInterval = setInterval(this.deviceSwipe, 3000)

    this.innerWidth = window.innerWidth
    this.innerHeight = window.innerHeight

    window.addEventListener('resize', this.resetSize)
    window.addEventListener('mouseup', this.mouseUpEvent)
    window.addEventListener('mousemove', this.mouseMoveEvent)
    window.addEventListener('mousewheel', this.mouseWheelEvent)
    window.addEventListener('click', this.windowClick)

    this.$nextTick(function () {
      self.monthAlarmAnaliseChart = echarts.init(document.getElementById('monthAlarmAnaliseChartBox'))
      self.deviceIncreaseChart = echarts.init(document.getElementById('deviceIncreaseChartBox'))
      self.signalLevelChart = echarts.init(document.getElementById('signalLevelChartBox'))
      self.monthAlarmChart = echarts.init(document.getElementById('monthAlarmChartBox'))

      self.monthAlarmAnaliseChart.on('legendselectchanged', function (params: any) {
        console.log(params)
      })

      self.deviceIncreaseChart.on('legendselectchanged', function (params: any) {
        console.log(params)
      })

      self.signalLevelChart.on('legendselectchanged', function (params: any) {
        console.log(params)
      })

      self.loadMonthAlarmAnaliseChart()
      self.loadDeviceIncreaseChart()
      self.loadSignalLevelChart()
      self.loadAlarmHandleRecord()
    })

    this.alarmType = ''
    this.alarmPage = 1

    // this.loadDeviceList()
    this.loadAlarmStatus()
    this.loadDeviceStatus()
    this.loadAlarmLogs()

    this.startWebSocket()

    if (this.refreshInterval !== null) {
      clearInterval(this.refreshInterval)
    }

    this.refreshInterval = setInterval(function () {
      self.loadMonthAlarmAnaliseChart()
      self.loadDeviceIncreaseChart()
      self.loadSignalLevelChart()
      self.loadAlarmHandleRecord()
    }, 60000)

    if (this.checkLoginInterval !== null) {
      clearInterval(this.checkLoginInterval)
    }

    this.checkLoginInterval = setInterval(function () {
      self.checkLogin()
    }, 60000)
  },
  unmounted () {
    if (this.timeInterval !== null) {
      clearInterval(this.timeInterval)
    }

    if (this.deviceSwipeInterval !== null) {
      clearInterval(this.deviceSwipeInterval)
    }

    if (this.refreshInterval !== null) {
      clearInterval(this.refreshInterval)
    }

    if (this.checkLoginInterval !== null) {
      clearInterval(this.checkLoginInterval)
    }

    window.removeEventListener('resize', this.resetSize)
    window.removeEventListener('mouseup', this.mouseUpEvent)
    window.removeEventListener('mousemove', this.mouseMoveEvent)

    this.monthAlarmAnaliseChart.dispose()
    this.deviceIncreaseChart.dispose()
    this.signalLevelChart.dispose()
    this.monthAlarmChart.dispose()

    this.stopWebsocket()
  },
  computed: {
    mapStyl: function () {
      const res = {
        display: 'block'
      }

      if (this.activeTab !== 'map') {
        res.display = 'none'
      }

      return res
    },
    deviceBoxStyl () {
      if (this.activeTab === 'map') {
        return {
          display: 'none'
        }
      } else {
        return {}
      }
    },
    contextMenuStyl () {
      if (this.contextEvent !== undefined && this.contextEvent !== null) {
        return {
          left: this.contextEvent.pageX + 'px',
          top: this.contextEvent.pageY + 'px'
        }
      }

      return {}
    }
  },
  directives: {
    loadMore: {
      mounted (el: any, binding: any) {
        const selectWrap = el.querySelector('.el-scrollbar__wrap')

        selectWrap.addEventListener('scroll', function (e: any) {
          const scrollDistance = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight

          if (scrollDistance < 10) {
            binding.value()
          }
        })
      }
    }
  },
  methods: {
    hideAlarmTables: function () {
      this.showAlarmInfoTable = false
      this.showOfflineDeviceTable = false
      this.showBrokenDeviceTable = false
      this.showTodayAlarmTable = false
    },
    setTimeStr: function () {
      let str = ''

      const d = new Date()

      str += d.getFullYear()
      str += '-'
      str += d.getMonth() >= 9 ? d.getMonth() + 1 : '0' + (d.getMonth() + 1)
      str += '-'
      str += d.getDate() >= 10 ? d.getDate() : '0' + d.getDate()
      str += ' '
      str += d.getHours() >= 10 ? d.getHours() : '0' + d.getHours()
      str += ':'
      str += d.getMinutes() >= 10 ? d.getMinutes() : '0' + d.getMinutes()
      str += ':'
      str += d.getSeconds() >= 10 ? d.getSeconds() : '0' + d.getSeconds()
      str += ' '

      str += ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'][d.getDay()]

      this.timeStr = str
    },
    initMonth: function () {
      let str = ''

      const d = new Date()

      str += d.getFullYear()
      str += '-'
      str += d.getMonth() >= 9 ? d.getMonth() + 1 : '0' + (d.getMonth() + 1)

      this.selectedMonth = str
    },
    checkLogin: function () {
      checkLoginApi({
        token: this.token
      }).then((res: any) => {
        if (res.flag === false) {
          this.wsCache.delete('name')
          this.wsCache.delete('token')
          this.wsCache.delete('admin_id')
          this.wsCache.delete('is_police')
          this.wsCache.delete('is_manager')
          this.wsCache.delete('type')

          this.$router.replace({name: 'Login'})
        }
      })
    },
    setting: function () {
      /*
      if (window.newWindow !== undefined) {
        window.newWindow({
          width: 800,
          height: 500,
          title: '系统设置',
          resizable: false,
          url: 'setting'
        })
      } else {
        this.$router.replace({ name: 'Setting' })
      }
       */

      this.selectedDeviceType = 'all'
      this.showCameraBox = false
      this.activeTab = 'system_setting'
    },
    closeSetting: function () {
      this.selectedDeviceType = 'all'
      this.showCameraBox = false
      this.activeTab = 'map'
    },
    toggleFullScreen: function () {
      const isFullscreen = this.wsCache.get('is_fullscreen')

      if (isFullscreen === true) {
        document.exitFullscreen()

        this.wsCache.set('is_fullscreen', false)
      } else {
        const ele = document.getElementById('app');

        if (ele !== null) {
          ele.requestFullscreen()

          this.wsCache.set('is_fullscreen', true)
        }
      }
    },
    doLogout: function () {
      document.exitFullscreen()

      this.wsCache.set('is_fullscreen', false)

      this.wsCache.delete('name')
      this.wsCache.delete('token')
      this.wsCache.delete('admin_id')
      this.wsCache.delete('is_police')
      this.wsCache.delete('is_manager')
      this.wsCache.delete('type')

      this.$router.replace({name: 'Login'})
    },
    mouseUpEvent: function () {
      this.mouseDown = false
    },
    mouseWheelEvent: function (e: any) {
      if (this.mouseEnter) {
        e.preventDefault()

        this.wheelCount ++

        if (this.wheelCount % 30 !== 0) {
          return
        }

        if (e.deltaY > 0) {
          if (this.showDeviceIndex <= 0) {
            this.showDeviceIndex = this.deviceTypes.length - 4
          }

          this.showDeviceIndex--
        } else {
          if (this.showDeviceIndex >= this.deviceTypes.length - 4) {
            this.showDeviceIndex = 0
          }

          this.showDeviceIndex++
        }
      }
    },
    mouseMoveEvent: function (e: any) {
      if (!this.mouseDown) {
        return
      }

      if (this.deviceTypes.length <= 4) {
        return
      }

      const index = Math.floor((e.clientY - this.initPointY) / 50)

      if (index > 0) {
        if (this.showDeviceIndex == 0) {
          this.initIndex = this.deviceTypes.length + index - 4
        }
      } else {
        if (this.showDeviceIndex == this.deviceTypes.length - 4) {
          this.initIndex = index
        }
      }

      this.showDeviceIndex = this.initIndex - index
    },
    mouseDownDeviceArea: function (e: any) {
      this.mouseDown = true

      this.initPointX = e.clientX
      this.initPointY = e.clientY

      this.initIndex = this.showDeviceIndex
    },
    mouseEnterDeviceArea: function () {
      this.mouseEnter = true
      this.wheelCount = 0
    },
    mouseLeaveDeviceArea: function () {
      this.mouseEnter = false
    },
    windowClick: function () {
      if (!this.contextMenuCovered) {
        this.contextMenuShow = false
      }

      if (!this.checkedDeviceCovered) {
        this.showCheckDeviceTypeBox = false
      }
    },
    toggleCheckDeviceType: function () {
      this.showCheckDeviceTypeBox = !this.showCheckDeviceTypeBox
    },
    toggleDeviceTree: function () {
      this.showDeviceTree = !this.showDeviceTree
    },
    checkedDeviceChanged: function () {
      this.wsCache.set('checked_device_types', JSON.stringify(this.checkedDeviceTypes))

      this.setDeviceTypes()
    },
    checkedDeviceEnter: function () {
      this.checkedDeviceCovered = true
    },
    checkedDeviceLeave: function () {
      this.checkedDeviceCovered = false
    },
    setDeviceTypes: function () {
      let tmp = []

      for (let i = 0; i < this.allDeviceTypes.length; i++) {
        if (this.checkedDeviceTypes.indexOf(this.allDeviceTypes[i].deviceType) >= 0) {
          tmp.push(this.allDeviceTypes[i])
        }
      }

      if (tmp.length > 4) {
        tmp = tmp.concat(tmp.slice(0, 4))
      }

      this.deviceTypes = JSON.parse(JSON.stringify(tmp))
      this.showDeviceIndex = 0
    },
    deviceSwipe: function () {
      if (this.mouseEnter) {
        return
      }

      if (this.showDeviceIndex < this.deviceTypes.length - 4) {
        this.showDeviceIndex++
      } else {
        this.showDeviceIndex = 0
      }
    },
    selectAllDevice: function () {
      this.currentDeviceIndex = -1
      this.selectedDeviceType = ''
      this.showDeviceTree = false
      this.devicesData = []

      this.activeTab = 'map'
      this.showDeviceType = 'all'
      this.selectedServiceCenter = 0
      this.selectedServiceCenterLevel = 0
      this.selectedHostNo = ''
      this.alarmType = ''
      this.alarmPage = 1

      this.hideAlarmTables()

      this.showAlarmInfoTable = true

      const self = this

      this.$nextTick(() => {
        self.loadAlarmStatus()
        self.loadDeviceStatus()
        self.loadAlarmLogs()

        self.loadMonthAlarmAnaliseChart()
        self.loadDeviceIncreaseChart()
        self.loadSignalLevelChart()
        self.loadAlarmHandleRecord()

        self.loadDeviceList()
      })
    },
    selectDevice: function (index: number) {
      const deviceType = this.deviceTypes[index].deviceType

      if (deviceType === 'elec_alarm2') {
        this.$router.replace({name: 'ElecAlarm', query: {open_type: 'route'}})
      } else {
        this.currentDeviceIndex = index
        this.selectedDeviceType = deviceType
        this.alarmType = ''
        this.alarmPage = 1

        this.loadAlarmStatus()
        this.loadDeviceStatus()
        this.loadAlarmLogs()

        this.loadMonthAlarmAnaliseChart()
        this.loadDeviceIncreaseChart()
        this.loadSignalLevelChart()
        this.loadAlarmHandleRecord()
        this.loadDeviceList()

        this.showDeviceType = 'all'
        this.selectedServiceCenter = 0
        this.selectedServiceCenterLevel = 0
        this.selectedHostNo = ''

        if (deviceType === 'fire_alarm') {
          this.activeTab = 'fire_alarm'
          this.loadDevices()
        } else if (deviceType === 'gas_alarm') {
          this.activeTab = 'gas_alarm'
          this.loadDevices()
        } else if (deviceType === 'access_alarm') {
          this.activeTab = 'access_alarm'
          this.loadDevices()
        } else if (deviceType === 'water_alarm') {
          this.activeTab = 'water_alarm'
          this.loadDevices()
        } else if (deviceType === 'emer_alarm') {
          this.activeTab = 'emer_alarm'
          this.loadDevices()
        } else if (deviceType === 'elec_alarm') {
          this.activeTab = 'device_list'
          this.loadDevices()
        } else if (deviceType === 'camera_alarm') {
          this.activeTab = 'camera_alarm'
          this.showCameraBox = true
          this.showDeviceTree = true
        } else {
          this.activeTab = 'map'
        }

        if (deviceType !== 'camera_alarm') {
          this.showCameraBox = false
        }
      }
    },
    resetSize: function () {
      const self = this

      this.innerWidth = window.innerWidth
      this.innerHeight = window.innerHeight

      this.$nextTick(() => {
        self.monthAlarmAnaliseChart.resize()
        self.deviceIncreaseChart.resize()
        self.signalLevelChart.resize()
        self.monthAlarmChart.resize()
      })
    },
    switchTab: function (tab: string) {
      this.activeTab = tab
    },
    syncSeatName: function (seatName: string) {
      this.seatName = seatName
      this.wsCache.set('seat_name', seatName)
    },
    loadAlarmStatus: function () {
      alarmStatusApi({
        token: this.token,
        host_type: this.selectedDeviceType
      }).then((res: any) => {
        this.alarmStatus.alarmCnt = res.alarm_cnt
        this.alarmStatus.untreatedCnt = res.untreated_cnt
        this.alarmStatus.handlingCnt = res.handling_cnt
        this.alarmStatus.finishedCnt = res.finished_cnt
      })
    },
    loadDeviceStatus: function () {
      deviceStatusApi({
        token: this.token,
        host_type: this.selectedDeviceType
      }).then((res: any) => {
        this.deviceStatus.totalCnt = res.total_cnt
        this.deviceStatus.onlineCnt = res.online_cnt
        this.deviceStatus.offlineCnt = res.offline_cnt
        this.deviceStatus.brokenCnt = res.broken_cnt
        this.deviceStatus.alarmCnt = res.alarm_cnt
      })
    },
    loadDeviceList: function () {
      const self = this
      const deviceType = this.selectedDeviceType

      /*
      for (let i = 0; i < this.markers.length; i++) {
        if (this.markers[i]) {
          this.markers[i].remove()
          this.markers[i] = null
        }
      }
       */
      this.TMap.clearOverLays()

      deviceListApi({
        token: this.token,
        host_type: this.selectedDeviceType
      }).then((res: any) => {
        if (deviceType !== '') {
          if (this.adminType == '2') {
            this.initRegionDeviceTreeData(res, '')
          } else {
            this.initDeviceTreeData(res, '')
          }

          this.tempDeviceData = JSON.parse(JSON.stringify(res))
        }

        this.markers = []
        this.geometries = []

        const users = res.users

        users.forEach((item: any) => {
          this.geometries.push({
            position: { lat: item.latitude, lng: item.longitude }
          })

          if (item.longitude !== null && item.latitude !== null) {
            const lnglat = gcj02ToWgs84(item.longitude, item.latitude)

            // eslint-disable-next-line no-undef
            let position = new T.LngLat(lnglat[0], lnglat[1])
            // eslint-disable-next-line no-undef
            let marker = new T.Marker(position)

            this.TMap.addOverLay(marker)

            marker.addEventListener('click', function () {
              self.showSelectedUser = false
              /*
              self.mapHostType = item.host_type
              self.mapHostNo = item.host_no
               */
              console.log('Main 1594 :::: ', item)
              self.selectedUser = item
              self.$nextTick(function () {
                self.showSelectedUser = true
              })
            })

            this.markers.push(marker)
          }

          /*
          marker.on('click', function (e) {
            var extData = e.layer.getExtData();

            // self.selectedUser = extData
            // self.showSelectedUser = true
            self.showMapUser = true
            self.mapHostType = extData.host_type
            self.mapHostNo = extData.host_no
          })
           */

          // this.markerClusterObj.addMarkers([marker])
        })


        /*
        const features = []

        for (let i = 0; i < this.geometries.length; i++) {
          features.push({
            'type': 'feature',
            'geometry': {
              'type': 'Point',
              'coordinates': [
                this.geometries[i].position.lng,
                this.geometries[i].position.lat
              ]
            },
            'properties': {}
          })
        }

        this.markers = {
          'type': 'FeatureCollection',
          'name': 'Devices',
          'features': features
        }

        if (this.cluster !== undefined && this.cluster !== null) {
          this.cluster.setBaseImage({
            image: 'http://lkimgyt.luokuang.com/1587372354848.png'
          })

          this.cluster.setData(this.markers)
        }

         */
      })
    },
    initDeviceTreeData: function (res: any, filter: string) {
      const self = this

      const serviceCenterId = res.service_center_id
      const serviceCenters = res.service_centers
      const users = res.users
      const devices = res.devices

      this.usersData = users

      const tmp: any = {}

      const now = (new Date()).getTime()

      devices.forEach((item: any) => {
        item.label = item.name === null ? item.host_no + '_' + item.device_no : item.name
        item.node_type = 'device'
        item.device_type = self.selectedDeviceType
        item.nodeId = 'd_' + item.host_no + '_' + item.device_no

        if (filter !== '' && item.label.indexOf(filter) < 0) {
          return
        }

        if (self.selectedDeviceType !== 'elec_alarm') {
          const lastMsgAt = (new Date(item.last_msg_at)).getTime()

          if (now - lastMsgAt > 24 * 3600 * 1000) {
            item.online_status = '0'
          } else {
            item.online_status = '1'
          }
        } else {
          item.online_status = item.online_status + ''
        }

        if (tmp['device_' + item.host_no] === undefined) {
          tmp['device_' + item.host_no] = []
        }

        tmp['device_' + item.host_no].push(item)
      })

      this.geometries = []

      users.forEach((item: any) => {
        if (this.adminType != 3) {
          if (item.service_center_level_4 === '0') {
            return
          }
        }

        item.name = item.name === null || item.name === '' ? item.host_no : item.name
        item.label = '[' + item.host_no + ']' + item.name
        item.node_type = 'user'
        item.device_type = self.selectedDeviceType
        item.nodeId = 'u_' + item.host_no
        item.device_cnt = 0

        if (tmp['device_' + item.host_no] !== undefined && tmp['device_' + item.host_no].length > 0) {
          item.children = tmp['device_' + item.host_no]
          item.device_cnt = item.children.length
        } else {
          item.children = []
          item.device_cnt = 0

          if (filter !== '' && item.label.indexOf(filter) < 0) {
            return
          }
        }

        if (this.adminType == '3') { // 当用户为分控平台时
          if (item.service_center_level_4 !== '0') {
            if (tmp['service_center_' + item.service_center_level_4] === undefined) {
              tmp['service_center_' + item.service_center_level_4] = []
            }

            tmp['service_center_' + item.service_center_level_4].push(item)
          } else if (item.service_center_level_3 !== '0') {
            if (tmp['service_center_' + item.service_center_level_3] === undefined) {
              tmp['service_center_' + item.service_center_level_3] = []
            }

            tmp['service_center_' + item.service_center_level_3].push(item)
          } else if (item.service_center_level_2 !== '0') {
            if (tmp['service_center_' + item.service_center_level_2] === undefined) {
              tmp['service_center_' + item.service_center_level_2] = []
            }

            tmp['service_center_' + item.service_center_level_2].push(item)
          } else if (item.service_center_level_1 !== '0') {
            if (tmp['service_center_' + item.service_center_level_1] === undefined) {
              tmp['service_center_' + item.service_center_level_1] = []
            }

            tmp['service_center_' + item.service_center_level_1].push(item)
          }
        } else {
          if (tmp['service_center_' + item.service_center_level_4] === undefined) {
            tmp['service_center_' + item.service_center_level_4] = []
          }

          tmp['service_center_' + item.service_center_level_4].push(item)
        }

        /*
        this.geometries.push({
          position: { lat: item.latitude, lng: item.longitude }
        })
         */
      })

      serviceCenters.forEach((item: any) => {
        if (item.level === '4') {
          item.label = item.name
          item.nodeId = 's_' + item.id
          item.device_cnt = 0
          item.user_cnt = 0

          if (tmp['service_center_' + item.id] !== undefined) {
            item.children = tmp['service_center_' + item.id]
            item.device_cnt = item.children.reduce((sum: number, e: any) => sum + Number(e.device_cnt || 0), 0)
            item.user_cnt = item.children.length

            if (['shop_alarm', 'lock_alarm', 'shike_alarm', 'charge_station'].indexOf(self.selectedDeviceType) >= 0) {
              item.label += '(' + item.user_cnt + ')'
            } else {
              item.label += '(' + item.device_cnt + '/' + item.user_cnt + ')'
            }
          } else {
            /*
            item.children = []

            if (filter !== '' && item.label.indexOf(filter) < 0) {
              return
            }
             */

            return
          }

          if (tmp['service_center_' + item.parent_id] === undefined) {
            tmp['service_center_' + item.parent_id] = []
          }

          tmp['service_center_' + item.parent_id].push(item)
        }
      })

      serviceCenters.forEach((item: any) => {
        if (item.level === '3') {
          item.label = item.name
          item.nodeId = 's_' + item.id
          item.device_cnt = 0
          item.user_cnt = 0

          if (tmp['service_center_' + item.id] !== undefined) {
            item.children = tmp['service_center_' + item.id]
            item.device_cnt = item.children.reduce((sum: number, e: any) => sum + Number(e.device_cnt || 0), 0)
            item.user_cnt = item.children.reduce((sum: number, e: any) => sum + Number(e.user_cnt || 0), 0)

            if (['shop_alarm', 'lock_alarm', 'shike_alarm', 'charge_station'].indexOf(self.selectedDeviceType) >= 0) {
              item.label += '(' + item.user_cnt + ')'
            } else {
              item.label += '(' + item.device_cnt + '/' + item.user_cnt + ')'
            }
          } else {
            /*
            item.children = []

            if (filter !== '' && item.label.indexOf(filter) < 0) {
              return
            }
             */

            return
          }

          if (tmp['service_center_' + item.parent_id] === undefined) {
            tmp['service_center_' + item.parent_id] = []
          }

          tmp['service_center_' + item.parent_id].push(item)
        }
      })

      serviceCenters.forEach((item: any) => {
        if (item.level === '2') {
          item.label = item.name
          item.nodeId = 's_' + item.id
          item.device_cnt = 0
          item.user_cnt = 0

          if (tmp['service_center_' + item.id] !== undefined) {
            item.children = tmp['service_center_' + item.id]
            item.device_cnt = item.children.reduce((sum: number, e: any) => sum + Number(e.device_cnt || 0), 0)
            item.user_cnt = item.children.reduce((sum: number, e: any) => sum + Number(e.user_cnt || 0), 0)

            if (['shop_alarm', 'lock_alarm', 'shike_alarm', 'charge_station'].indexOf(self.selectedDeviceType) >= 0) {
              item.label += '(' + item.user_cnt + ')'
            } else {
              item.label += '(' + item.device_cnt + '/' + item.user_cnt + ')'
            }
          } else {
            /*
            if (filter !== '' && item.label.indexOf(filter) < 0) {
              return
            }
             */

            return
          }

          if (tmp['service_center_' + item.parent_id] === undefined) {
            tmp['service_center_' + item.parent_id] = []
          }

          tmp['service_center_' + item.parent_id].push(item)
        }
      })

      serviceCenters.forEach((item: any) => {
        if (item.level === '1') {
          item.label = item.name
          item.nodeId = 's_' + item.id
          item.device_cnt = 0
          item.user_cnt = 0

          if (tmp['service_center_' + item.id] !== undefined) {
            item.children = tmp['service_center_' + item.id]
            item.device_cnt = item.children.reduce((sum: number, e: any) => sum + Number(e.device_cnt || 0), 0)
            item.user_cnt = item.children.reduce((sum: number, e: any) => sum + Number(e.user_cnt || 0), 0)

            if (['shop_alarm', 'lock_alarm', 'shike_alarm', 'charge_station'].indexOf(self.selectedDeviceType) >= 0) {
              item.label += '(' + item.user_cnt + ')'
            } else {
              item.label += '(' + item.device_cnt + '/' + item.user_cnt + ')'
            }
          } else {
            /*
            if (filter !== '' && item.label.indexOf(filter) < 0) {
              return
            }
             */

            return
          }

          if (tmp['service_center_' + item.parent_id] === undefined) {
            tmp['service_center_' + item.parent_id] = []
          }

          tmp['service_center_' + item.parent_id].push(item)
        }
      })

      serviceCenters.forEach((item: any) => {
        if (item.id === serviceCenterId) {
          item.label = item.name
          item.nodeId = 's_' + item.id
          item.device_cnt = 0
          item.user_cnt = 0
          item.isExpand = true

          if (tmp['service_center_' + item.id] !== undefined) {
            item.children = tmp['service_center_' + item.id]
            item.device_cnt = item.children.reduce((sum: number, e: any) => sum + Number(e.device_cnt || 0), 0)
            item.user_cnt = item.children.reduce((sum: number, e: any) => sum + Number(e.user_cnt || 0), 0)

            if (['shop_alarm', 'lock_alarm', 'shike_alarm', 'charge_station'].indexOf(self.selectedDeviceType) >= 0) {
              item.label += '(' + item.user_cnt + ')'
            } else {
              item.label += '(' + item.device_cnt + '/' + item.user_cnt + ')'
            }
          }

          if (tmp['service_center_root'] === undefined) {
            tmp['service_center_root'] = []
          }

          tmp['service_center_root'].push(item)
        }
      })

      this.devicesData = tmp['service_center_root'] === undefined ? [] : tmp['service_center_root']
    },
    initRegionDeviceTreeData: function (res: any, filter: string) {
      const self = this

      const regionId = res.region_id
      const regions = res.regions
      const users = res.users
      const devices = res.devices

      this.usersData = users

      const tmp: any = {}

      const now = (new Date()).getTime()

      devices.forEach((item: any) => {
        item.label = item.name === null ? item.host_no + '_' + item.device_no : item.name
        item.node_type = 'device'
        item.device_type = self.selectedDeviceType
        item.nodeId = 'd_' + item.host_no + '_' + item.device_no

        if (filter !== '' && item.label.indexOf(filter) < 0) {
          return
        }

        if (self.selectedDeviceType !== 'elec_alarm') {
          const lastMsgAt = (new Date(item.last_msg_at)).getTime()

          if (now - lastMsgAt > 24 * 3600 * 1000) {
            item.online_status = '0'
          } else {
            item.online_status = '1'
          }
        } else {
          item.online_status = item.online_status + ''
        }

        if (tmp['device_' + item.host_no] === undefined) {
          tmp['device_' + item.host_no] = []
        }

        tmp['device_' + item.host_no].push(item)
      })

      this.geometries = []

      users.forEach((item: any) => {
        if (item.service_center_level_4 === '0') {
          return
        }

        item.name = item.name === null || item.name === '' ? item.host_no : item.name
        item.label = '[' + item.host_no + ']' + item.name
        item.node_type = 'user'
        item.device_type = self.selectedDeviceType
        item.nodeId = 'u_' + item.host_no
        item.device_cnt = 0

        if (tmp['device_' + item.host_no] !== undefined && tmp['device_' + item.host_no].length > 0) {
          item.children = tmp['device_' + item.host_no]
          item.device_cnt = item.children.length
        } else {
          item.children = []
          item.device_cnt = 0

          if (filter !== '' && item.label.indexOf(filter) < 0) {
            return
          }
        }

        if (item.zone != undefined && item.zone != 0) {
          if (tmp['s_' + item.zone] === undefined) {
            tmp['s_' + item.zone] = []
          }

          tmp['s_' + item.zone].push(item)
        } else {
          if (tmp['s_' + item.street] === undefined) {
            tmp['s_' + item.street] = []
          }

          tmp['s_' + item.street].push(item)
        }
      })

      regions.forEach((item: any) => {
        if (item.level === '5') {
          item.label = item.name
          item.nodeId = 's_' + item.id
          item.device_cnt = 0
          item.user_cnt = 0

          if (tmp['s_' + item.id] !== undefined) {
            item.children = tmp['s_' + item.id]
            item.device_cnt = item.children.reduce((sum: number, e: any) => sum + Number(e.device_cnt || 0), 0)
            item.user_cnt = item.children.length

            if (['shop_alarm', 'lock_alarm', 'shike_alarm', 'charge_station'].indexOf(self.selectedDeviceType) >= 0) {
              item.label += '(' + item.user_cnt + ')'
            } else {
              item.label += '(' + item.device_cnt + '/' + item.user_cnt + ')'
            }
          } else {
            return
          }

          if (tmp['s_' + item.parent_id] === undefined) {
            tmp['s_' + item.parent_id] = []
          }

          tmp['s_' + item.parent_id].push(item)
        }
      })

      regions.forEach((item: any) => {
        if (item.level === '4') {
          item.label = item.name
          item.nodeId = 's_' + item.id
          item.device_cnt = 0
          item.user_cnt = 0

          if (tmp['s_' + item.id] !== undefined) {
            item.children = tmp['s_' + item.id]
            item.device_cnt = item.children.reduce((sum: number, e: any) => sum + Number(e.device_cnt || 0), 0)
            item.user_cnt = item.children.length

            if (['shop_alarm', 'lock_alarm', 'shike_alarm', 'charge_station'].indexOf(self.selectedDeviceType) >= 0) {
              item.label += '(' + item.user_cnt + ')'
            } else {
              item.label += '(' + item.device_cnt + '/' + item.user_cnt + ')'
            }
          } else {
            return
          }

          if (tmp['s_' + item.parent_id] === undefined) {
            tmp['s_' + item.parent_id] = []
          }

          tmp['s_' + item.parent_id].push(item)
        }
      })

      regions.forEach((item: any) => {
        if (item.level === '3') {
          item.label = item.name
          item.nodeId = 's_' + item.id
          item.device_cnt = 0
          item.user_cnt = 0

          if (tmp['s_' + item.id] !== undefined) {
            item.children = tmp['s_' + item.id]
            item.device_cnt = item.children.reduce((sum: number, e: any) => sum + Number(e.device_cnt || 0), 0)
            item.user_cnt = item.children.reduce((sum: number, e: any) => sum + Number(e.user_cnt || 0), 0)

            if (['shop_alarm', 'lock_alarm', 'shike_alarm', 'charge_station'].indexOf(self.selectedDeviceType) >= 0) {
              item.label += '(' + item.user_cnt + ')'
            } else {
              item.label += '(' + item.device_cnt + '/' + item.user_cnt + ')'
            }
          } else {
            return
          }

          if (tmp['s_' + item.parent_id] === undefined) {
            tmp['s_' + item.parent_id] = []
          }

          tmp['s_' + item.parent_id].push(item)
        }
      })

      regions.forEach((item: any) => {
        if (item.level === '2') {
          item.label = item.name
          item.nodeId = 's_' + item.id
          item.device_cnt = 0
          item.user_cnt = 0

          if (tmp['s_' + item.id] !== undefined) {
            item.children = tmp['s_' + item.id]
            item.device_cnt = item.children.reduce((sum: number, e: any) => sum + Number(e.device_cnt || 0), 0)
            item.user_cnt = item.children.reduce((sum: number, e: any) => sum + Number(e.user_cnt || 0), 0)

            if (['shop_alarm', 'lock_alarm', 'shike_alarm', 'charge_station'].indexOf(self.selectedDeviceType) >= 0) {
              item.label += '(' + item.user_cnt + ')'
            } else {
              item.label += '(' + item.device_cnt + '/' + item.user_cnt + ')'
            }
          } else {
            return
          }

          if (tmp['s_' + item.parent_id] === undefined) {
            tmp['s_' + item.parent_id] = []
          }

          tmp['s_' + item.parent_id].push(item)
        }
      })

      regions.forEach((item: any) => {
        if (item.level === '1') {
          item.label = item.name
          item.nodeId = 's_' + item.id
          item.device_cnt = 0
          item.user_cnt = 0

          if (tmp['s_' + item.id] !== undefined) {
            item.children = tmp['s_' + item.id]
            item.device_cnt = item.children.reduce((sum: number, e: any) => sum + Number(e.device_cnt || 0), 0)
            item.user_cnt = item.children.reduce((sum: number, e: any) => sum + Number(e.user_cnt || 0), 0)

            if (['shop_alarm', 'lock_alarm', 'shike_alarm', 'charge_station'].indexOf(self.selectedDeviceType) >= 0) {
              item.label += '(' + item.user_cnt + ')'
            } else {
              item.label += '(' + item.device_cnt + '/' + item.user_cnt + ')'
            }
          } else {
            return
          }

          if (tmp['s_' + item.parent_id] === undefined) {
            tmp['s_' + item.parent_id] = []
          }

          tmp['s_' + item.parent_id].push(item)
        }
      })

      regions.forEach((item: any) => {
        if (item.id === regionId) {
          item.label = item.name
          item.nodeId = 's_' + item.id
          item.device_cnt = 0
          item.user_cnt = 0
          item.isExpand = true

          if (tmp['s_' + item.id] !== undefined) {
            item.children = tmp['s_' + item.id]
            item.device_cnt = item.children.reduce((sum: number, e: any) => sum + Number(e.device_cnt || 0), 0)
            item.user_cnt = item.children.reduce((sum: number, e: any) => sum + Number(e.user_cnt || 0), 0)

            if (['shop_alarm', 'lock_alarm', 'shike_alarm', 'charge_station'].indexOf(self.selectedDeviceType) >= 0) {
              item.label += '(' + item.user_cnt + ')'
            } else {
              item.label += '(' + item.device_cnt + '/' + item.user_cnt + ')'
            }
          } else {
            return
          }

          if (tmp['s_root'] === undefined) {
            tmp['s_root'] = []
          }

          tmp['s_root'].push(item)
        }
      })

      this.devicesData = tmp['s_root'] === undefined ? [] : tmp['s_root']
    },
    callFilterNode: function () {
      if (this.selectedDeviceType !== '') {
        const tmp = JSON.parse(JSON.stringify(this.tempDeviceData))

        if (this.adminType == '2') {
          this.initRegionDeviceTreeData(tmp, this.filterText.trim())
        } else {
          this.initDeviceTreeData(tmp, this.filterText.trim())
        }

        this.expandAll = this.filterText.trim() !== ''
      }
    },
    filterNode: function (value: string, data: Tree) {
      if (!value) return true

      if (data.label === null) {
        return false
      }

      return data.label.indexOf(value) >= 0
    },
    treeNodeClick: function (nodeId: string, node: any) {
      if (['shop_alarm', 'lock_alarm', 'shike_alarm'].indexOf(this.selectedDeviceType) >= 0) {
        if (node.node_type === 'user') {
          this.selectedUser = JSON.parse(JSON.stringify(node))
          this.showSelectedUser = true

          const lnglat = gcj02ToWgs84(this.selectedUser.longitude, this.selectedUser.latitude)

          // eslint-disable-next-line no-undef
          this.TMap.panTo(new T.LngLat(lnglat[0], lnglat[1]))
          /*
          this.LKMap.setCenter({
            lat: this.selectedUser.latitude,
            lng: this.selectedUser.longitude
          })
           */
        }
      } else if (['fire_alarm', 'gas_alarm', 'access_alarm', 'water_alarm', 'emer_alarm', 'elec_alarm'].indexOf(this.selectedDeviceType) >= 0) {
        this.activeTab = 'device_list'
        this.showDeviceType = 'all'
        this.selectedServiceCenter = node.id
        this.selectedServiceCenterLevel = node.level
        this.selectedHostNo = node.host_no

        this.loadDevices()
      } else if ('camera_alarm' === this.selectedDeviceType) {
        if (node.node_type === 'device') {
          this.cameraDevice = null
          this.cameraDevice = node
        }
      } else if ('charge_station' === this.selectedDeviceType) {
        if (node.node_type === 'user') {
          this.activeTab = 'charge_station'
          this.selectedChargeStation = node
        }
      } else {
        if (node.node_type === 'device') {
          for (let i = 0; i < this.usersData.length; i++) {
            if (this.usersData[i].host_no === node.host_no) {
              this.selectedUser = JSON.parse(JSON.stringify(this.usersData[i]))
              this.selectedUser.online_status = node.online_status
              this.selectedUser.csq = node.csq
              this.selectedUser.battery_voltage = node.battery_voltage

              this.showSelectedUser = true

              break
            }
          }
        }
      }
    },
    hideSelectedUser: function () {
      this.selectedUser = {}
      this.showSelectedUser = false
    },
    loadAlarmLogs: function () {
      loadLogApi({
        token: this.token,
        type: this.alarmType,
        host_type: this.selectedDeviceType,
        page: this.alarmPage
      }).then((res: any) => {
        this.alarmInfoData = res.logs
      })
    },
    loadMonthAlarmAnaliseChart: function () {
      monthAlarmAnaliseApi({
        token: this.token,
        host_type: this.selectedDeviceType
      }).then((res: any) => {
        this.initMonthAlarmAnaliseChart(res)
      })
    },
    loadDeviceIncreaseChart: function () {
      deviceIncreaseApi({
        token: this.token,
        host_type: this.selectedDeviceType
      }).then((res: any) => {
        this.initDeviceIncreaseChart(res)
      })
    },
    loadSignalLevelChart: function () {
      signalLevelApi({
        token: this.token,
        host_type: this.selectedDeviceType
      }).then((res: any) => {
        this.initSignalLevelChart(res)
      })
    },
    loadAlarmHandleRecord: function () {
      alarmHandleRecordApi({
        token: this.token,
        month: this.selectedMonth,
        host_type: this.selectedDeviceType
      }).then((res: any) => {
        this.initMonthAlarmChart(res)
      })
    },
    initMonthAlarmAnaliseChart: function (res: any) {
      const dateArr: string[] = res.alarm.reduce((t: string[], item: any) => {
        t.push(item.date)

        return t
      }, [])

      const offlineCntArr: number[] = res.offline.reduce((t: number[], item: any) => {
        t.push(item.cnt)

        return t
      }, [])

      const alarmCntArr: number[] = res.alarm.reduce((t: number[], item: any) => {
        t.push(item.cnt)

        return t
      }, [])

      const brokenCntArr: number[] = res.broken.reduce((t: number[], item: any) => {
        t.push(item.cnt)

        return t
      }, [])

      const option = {
        xAxis: {
          type: 'category',
          data: dateArr,
          axisLine: {
            lineStyle: {
              color: '#828fb1'
            }
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#fff'
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            lineStyle: {
              color: '#828fb1'
            }
          },
          axisLabel: {
            textStyle: {
              color: '#fff'
            },
            formatter: '{value}'
          },
          splitLine: {
            lineStyle: {
              color: ['#828fb1']
            }
          }
        },
        tooltip: {
          trigger: 'item',
          formatter: '{c} '
        },
        legend: {
          top: 0,
          left: '4%',
          itemWidth: 16,
          itemHeight: 12,
          icon: 'roundRect',
          textStyle: {
            color: '#fff',
            fontSize: 12
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '40px',
          containLabel: true,
          color: '#fff'
        },
        series: [{
          name: '设备离线',
          data: offlineCntArr,
          type: 'line',
          color: '#09954f'
        },
        {
          name: '设备报警',
          data: alarmCntArr,
          type: 'line',
          color: '#ffda67'
        },
        {
          name: '设备故障',
          data: brokenCntArr,
          type: 'line',
          color: '#ed5f6e'
        }]
      }

      this.monthAlarmAnaliseChart.setOption(option)
    },
    initDeviceIncreaseChart: function (res: any) {
      let dateArr: string[]

      if (res.new.length === 0) {
        dateArr = ['01月', '02月', '03月', '04月', '05月', '06月', '07月', '08月', '09月', '10月', '11月', '12月']
      } else {
        dateArr = res.new.reduce((t: string[], item: any) => {
          t.push(item.date)

          return t
        }, [])
      }

      const newArr = res.new.reduce((t: number[], item: any) => {
        t.push(item.cnt)

        return t
      }, [])

      const chargeArr = res.charge.reduce((t: number[], item: any) => {
        t.push(item.cnt)

        return t
      }, [])

      const selfArr = res.self.reduce((t: number[], item: any) => {
        t.push(item.cnt)

        return t
      }, [])

      const cancelArr = res.cancel.reduce((t: number[], item: any) => {
        t.push(item.cnt)

        return t
      }, [])

      const option = {
        xAxis: {
          type: 'category',
          data: dateArr,
          axisLine: {
            //  改变x轴颜色
            lineStyle: {
              color: '#828fb1'
            }
          },
          axisLabel: {
            //  改变x轴字体颜色和大小
            textStyle: {
              color: '#fff',
              fontSize: 12
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: ['#828fb1'],
              width: 1,
              type: 'solid'
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            //  改变y轴颜色
            show: true,
            lineStyle: {
              color: '#828fb1'
            }
          },
          axisLabel: {
            //  改变y轴字体颜色和大小
            // formatter: '{value} m³ ', //  给y轴添加单位
            textStyle: {
              color: '#fff',
              fontSize: 12
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['#315070'],
              width: 1,
              type: 'solid'
            }
          }
        },
        tooltip: {
          trigger: 'item',
          formatter: '{c} '
        },
        // 图例组件
        legend: {
          textStyle: {
            color: '#fff' // 图例文字颜色
          },
          right: '10%', // 距离右边10%
          top: '10%'
        },
        series: [{
          name: '设备新增',
          smooth: true,
          symbol: 'circle',
          symbolSize: 7,
          markPoint: {
            symbol: 'circle'
          },
          data: newArr,
          type: 'line',
          itemStyle: {
            normal: {
              label: {
                show: false,
                color: '#fff',
                fontSize: 12
              },
              color: '#22ac38',
              // borderColor: 'rgba(34,172,56,0.1)',
              borderWidth: 2
            }
          },
          lineStyle: {
            normal: {
              width: 2,
              color: '#22ac38',
              shadowColor: '#22ac38',
              shadowBlur: 10
            }
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0.5,
                color: 'rgba(34,172,56,0.5)' // 0% 处的颜色
              },
              {
                offset: 1,
                color: 'rgba(34,172,56,0.5)' // 100% 处的颜色
              }],
              global: false // 缺省为 false
            }
          }
        },
        {
          name: '设备托管',
          smooth: true,
          symbol: 'circle',
          symbolSize: 7,
          markPoint: {
            symbol: 'circle'
          },
          data: chargeArr,
          type: 'line',
          itemStyle: {
            normal: {
              label: {
                show: false,
                color: '#fff',
                fontSize: 12
              },
              color: '#e5901d'
              // borderColor: 'e5901d',
              // borderWidth: 2,
            }
          },
          lineStyle: {
            normal: {
              width: 2,
              color: '#e5901d',
              shadowColor: '#e5901d',
              shadowBlur: 10
            }
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0.5,
                color: 'rgba(229,144,29,0.5)' // 0% 处的颜色
              },
              {
                offset: 1,
                color: 'rgba(229,144,29,0)' // 100% 处的颜色
              }],
              global: false // 缺省为 false
            }
          }
        },
        {
          name: '设备自管',
          smooth: true,
          symbol: 'circle',
          symbolSize: 7,
          markPoint: {
            symbol: 'circle'
          },
          data: selfArr,
          type: 'line',
          itemStyle: {
            normal: {
              label: {
                show: false,
                color: '#fff',
                fontSize: 12
              },
              color: '#fff45c'
              // borderColor: '3D7EEB',
              // borderWidth: 2,
            }
          },
          lineStyle: {
            normal: {
              width: 2,
              color: '#fff45c',
              shadowColor: '#fff45c',
              shadowBlur: 10
            }
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0.5,
                color: 'rgba(255,244,92,0.5)' // 0% 处的颜色
              },
              {
                offset: 1,
                color: 'rgba(255,244,92,0)' // 100% 处的颜色
              }],
              global: false // 缺省为 false
            }
          }
        },
        {
          name: '设备退网',
          smooth: true,
          symbol: 'circle',
          symbolSize: 7,
          markPoint: {
            symbol: 'circle'
          },
          data: cancelArr,
          type: 'line',
          itemStyle: {
            normal: {
              label: {
                show: false,
                color: '#fff',
                fontSize: 12
              },
              color: '#00ffff'
              // borderColor: '3D7EEB',
              // borderWidth: 2,
            }
          },
          lineStyle: {
            normal: {
              width: 2,
              color: '#00ffff',
              shadowColor: '#00ffff',
              shadowBlur: 10
            }
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0.5,
                color: 'rgba(0,255,255,0.5)' // 0% 处的颜色
              },
              {
                offset: 1,
                color: 'rgba(0,255,255,0)' // 100% 处的颜色
              }],
              global: false // 缺省为 false
            }
          }
        }]
      }

      this.deviceIncreaseChart.setOption(option)
    },
    initSignalLevelChart: function (res: any) {
      const option = {
        title: {
          text: `${res.total}个`,
          top: '50%',
          left: '28.5%',
          textStyle: {
            fontSize: 16,
            color: '#fff',
            padding: [10, 0],
            textAlign: 'center'
          }
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b} '
        },
        graphic: {
          elements: [{
            type: 'text',
            left: '29%',
            top: '43%',
            style: {
              text: '用户数量',
              textAlign: 'center',
              fill: '#fff',
              fontSize: 14
            }
          }
          ]
        },
        legend: {
          orient: 'vertical',
          x: '70%',
          y: 'center',
          itemWidth: 10,
          itemHeight: 10,
          align: 'left',
          textStyle: {
            fontSize: 14,
            color: '#fff'
          },
          formatter: function (name: any) {
            const data = option.series[0].data // 获取series中的data
            let total = 0
            let tarValue
            for (let i = 0, l = data.length; i < l; i++) {
              total += data[i].value

              if (data[i].name === name) {
                if (data[i].value === 0) {
                // return name + ' ' + '0%';
                  return name + ' 0'
                } else {
                  tarValue = data[i].value
                }
              }
            }
            // let p = (tarValue / total) * 100
            // return name + ' ' + ' ' + p.toFixed(2) + '%';
            return name + ' ' + tarValue
          }
        },
        series: [{
          type: 'pie',
          radius: ['38%', '60%'],
          center: ['35%', '50%'],
          // roseType: 'area',
          color: [
            '#0E7CE2',
            '#FF8352',
            '#E271DE',
            '#F8456B',
            '#00FFFF',
            '#4AEAB0'
          ],
          data: [{
            value: res.high,
            name: '信号强'
          },
          {
            value: res.middle,
            name: '信号中'
          },
          {
            value: res.low,
            name: '信号弱'
          },
          {
            value: res.no_signal,
            name: '无信号'
          }],
          label: {
            formatter: '{d}%',
            color: '#fff'
          },
          labelLine: {
            normal: {
              show: true,
              length: 10,
              length2: 20,
              lineStyle: {
                color: '#12EABE',
                width: 1
              }
            }
          }
        }
        ]
      }

      this.signalLevelChart.setOption(option)
    },
    initMonthAlarmChart: function (param: any) {
      const data = [
        param.video_check_cnt,
        param.call_user_cnt,
        param.police_cnt,
        param.public_police_cnt,
        param.device_error_cnt,
        param.device_test_cnt,
        param.user_error_cnt
      ]

      const option = {
        grid: {
          left: 60
        },
        xAxis: {
          data: [
            '视频接警',
            '致电用户',
            '前置巡查',
            '公共出警',
            '设备误报',
            '设备测试',
            '人为报警'
          ],
          axisLine: {
            lineStyle: {
              color: '#828fb1'
            }
          },
          axisLabel: {
            color: '#fff',
            fontSize: 12,
            interval: 0
          }
        },
        yAxis: {
          nameTextStyle: {
            color: '#fff'
          },
          axisLine: {
            lineStyle: {
              color: '#3d5269'
            }
          },
          axisLabel: {
            color: '#fff'
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#828fb1'
            }
          }
        },
        series: [{
          type: 'bar',
          barWidth: 20,
          itemStyle: {
            // 提供的工具函数生成渐变颜色
            color: function (params: any) {
              const colorList = [
                ['#88c3ff', '#1b87ec'],
                ['#b4fe0b', '#3c8205'],
                ['#fe7891', '#6d0909'],
                ['#f4cbab', '#944a0d'],
                ['#d996ff', '#6311b3'],
                ['#a4fee5', '#12bc4f'],
                ['#fdf640', '#dfb406']
              ]

              let index = params.dataIndex

              if (params.dataIndex >= colorList.length) {
                index = params.dataIndex - colorList.length
              }

              return new echarts.graphic.LinearGradient(0, 0, 0, 1,
                [{
                  offset: 0,
                  color: colorList[index][0]
                },
                {
                  offset: 0.5,
                  color: colorList[index][1]
                }],
                false
              )
            }
          },
          label: {
            normal: {
              show: true,
              fontSize: 12,
              color: '#ffffff',
              position: 'top'
            }
          },
          data: data
        }]
      }

      this.monthAlarmChart.setOption(option)
    },
    startWebSocket () {
      const self = this

      if (this.wsInterval !== null) {
        clearInterval(this.wsInterval)

        this.wsInterval = null
      }

      this.ws = new WebSocket('wss://www.baoanquan.vip/wss')

      this.ws.onclose = function () {
        if (self.wsInterval !== null) {
          clearInterval(self.wsInterval)
        }

        self.wsInterval = null
        self.ws = null
      }

      this.ws.onopen = function () {
        const data = JSON.stringify({
          key: 'user_login',
          token: self.token
        }) + '\n'

        self.ws.send(data)

        self.wsInterval = setInterval(() => {
          if (self.ws !== null) {
            const data = JSON.stringify({
              key: 'heartbeat',
              token: self.token
            }) + '\n'

            self.ws.send(data)
          }
        }, 30000)
      }

      this.ws.onerror = function (e: any) {
        clearInterval(self.wsInterval)

        self.wsInterval = null
        self.ws = null

        console.log(e)
      }

      this.ws.onmessage = function (data: any) {
        const msg = JSON.parse(data.data)

        if (msg.msg_type === 'log_msg') {
          if (msg.is_alarm === 1 || msg.is_alarm === '1') {
            const flag = self.hasAlarmInfo(msg)

            if (flag) {
              return
            }

            if (msg.alarm_log_type === 'resend' && self.handlingLog.log_id === msg.log_id) {
              return
            }

            if (msg.log_status === undefined) {
              msg.log_status = '0'
              msg.log_status_txt = '未处理'
            } else {
              msg.log_status = msg.log_status + ''
            }

            self.alarmInfoData.unshift(msg)

            if (self.alarmInfoData.length > 15) {
              self.alarmInfoData.pop()
            }

            if (self.isPolice === '1') {
              if (!self.handlingStatus) {
                self.manualOpen = false
                self.loadAlarmLog(msg.log_id)
              }
            }

            self.loadAlarmStatus()
          }
        } else if (msg.msg_type === 'receive_alarm') {
          const tmp = JSON.parse(JSON.stringify(self.alarmInfoData))

          for (let i = 0; i < tmp.length; i++) {
            if (tmp[i].log_id === (msg.log_id + '')) {
              tmp[i].log_status_txt = msg.log_status_txt
              tmp[i].log_status = msg.log_status
              tmp[i].worker_name = msg.worker_name

              break
            }
          }

          self.alarmInfoData = tmp

          if (!self.handlingStatus && self.handlingLog.log_id === msg.log_id) {
            self.showAlarmInfo = false
          }

          self.loadAlarmStatus()
        } else if (msg.msg_type === 'verify_alarm') {
          const tmp = JSON.parse(JSON.stringify(self.alarmInfoData))

          for (let i = 0; i < tmp.length; i++) {
            if (tmp[i].log_id === (msg.log_id + '')) {
              tmp[i].log_status_txt = msg.log_status_txt
              tmp[i].log_status = msg.log_status
              tmp[i].worker_name = msg.worker_name

              break
            }
          }

          self.alarmInfoData = tmp

          if (!self.handlingStatus && self.handlingLog.log_id === msg.log_id) {
            self.showAlarmInfo = false
          }

          self.loadAlarmStatus()
        }
      }
    },
    stopWebsocket () {
      if (this.wsInterval !== null) {
        clearInterval(this.wsInterval)
      }

      this.wsInterval = null

      this.ws.close()
      this.ws = null
    },
    hasAlarmInfo (msg: any) {
      for (let i = 0; i < this.alarmInfoData.length; i++) {
        if (this.alarmInfoData[i].log_id === msg.log_id) {
          return true
        }
      }

      return false
    },
    handleRowClick (data: any) {
      this.manualOpen = true
      this.loadAlarmLog(data.log_id, 'history')
    },
    loadAlarmLog (logId: string, type: string) {
      getAlarmLogApi({
        token: this.token,
        log_id: logId
      }).then((res: any) => {
        if (type === 'history') {
          this.showHistoryLog = true
          this.historyLog = res.log_data

          if (res.log_data !== undefined) {
            this.center = {
              lat: res.log_data.latitude - 0 + 0.0025,
              lng: res.log_data.longitude - 0 + 0.0055
            }

            const lnglat = gcj02ToWgs84(res.log_data.longitude - 0 + 0.0055, res.log_data.latitude - 0 + 0.0025)

            // eslint-disable-next-line no-undef
            this.TMap.centerAndZoom(new T.LngLat(lnglat[0], lnglat[1]), 14)
            /*
            this.LKMap.setZoom(14)
            this.LKMap.setCenter({
              lat: res.log_data.latitude - 0 + 0.0025,
              lng: res.log_data.longitude - 0 + 0.0055
            })
             */
          }

          this.historyAlarmFormData = {
            verifyMethod: res.log_data.verify_method == '0' ? '': res.log_data.verify_method,
            verifyRecord: res.log_data.verify_record,
            handleRecord: res.log_data.handle_record
          }
        } else {
          this.showAlarmInfo = true
          this.handlingLog = res.log_data

          if (res.log_data !== undefined) {
            this.center = {
              lat: res.log_data.latitude,
              lng: res.log_data.longitude
            }

            const lnglat = gcj02ToWgs84(res.log_data.longitude, res.log_data.latitude)

            // eslint-disable-next-line no-undef
            this.TMap.panTo(new T.LngLat(lnglat[0], lnglat[1]))
            /*
            this.LKMap.setCenter({
              lat: res.log_data.latitude,
              lng: res.log_data.longitude
            })
             */
          }

          this.alarmFormData = {
            verifyMethod: res.log_data.verify_method == '0' ? '': res.log.verify_method,
            verifyRecord: res.log_data.verify_record,
            handleRecord: res.log_data.handle_record
          }

          this.$refs.alarmAudio.play()
        }

        if (res.log_data.video_sn !== '' && (res.log_data.server_id === 3 || res.log_data.server_id === 4)) {
          this.getVideoUrl(res.log_data)
        }
      })
    },
    getVideoUrl (log: any) {
      getMediaUrlApi({
        device_id: log.video_sn,
        channel_no: log.video_sn
      }).then((res: any) => {
        this.play(res.data.rtc)
      })
    },
    receiveAlarm (type: string) {
      let log_id = ''

      if (type === 'history') {
        log_id = this.historyLog.log_id
      } else {
        log_id = this.handlingLog.log_id
      }

      receiveAlarmApi({
        token: this.token,
        log_id: log_id
      }).then((res: any) => {
        if (type === 'history') {
          this.historyLog.log_status = res.log_status
          this.handlingStatus = true
        } else {
          this.handlingLog.log_status = res.log_status

          this.$refs.alarmAudio.pause()
        }
      })
    },
    verifyAlarmInfo () {
      const self = this

      this.$refs.handleAlarmForm.validate((valid: boolean) => {
        if (valid) {
          verifyAlarmApi({
            token: this.token,
            log_id: this.handlingLog.log_id,
            verify_method: this.alarmFormData.verifyMethod,
            verify_record: this.alarmFormData.verifyRecord,
            handle_record: this.alarmFormData.handleRecord
          }).then((res: any) => {
            this.showAlarmInfo = false
            this.handlingStatus = false
          })
        } else {
          if (self.manualOpen) {
            self.manualOpen = false
            self.showAlarmInfo = false
            self.handlingStatus = false
          }
        }
      })
    },
    hideAlarmInfo () {
      this.pause()
      this.showAlarmInfo = false
    },
    verifyHistoryAlarmInfo () {
      const self = this

      this.$refs.handleHistoryAlarmForm.validate((valid: boolean) => {
        if (valid) {
          verifyAlarmApi({
            token: this.token,
            log_id: this.historyLog.log_id,
            verify_method: this.historyAlarmFormData.verifyMethod,
            verify_record: this.historyAlarmFormData.verifyRecord,
            handle_record: this.historyAlarmFormData.handleRecord
          }).then((res: any) => {
            this.showHistoryLog = false
          })
        } else {
          if (self.manualOpen) {
            self.showHistoryLog = false
            self.manualOpen = false
          }
        }
      })
    },
    hideHistoryAlarmInfo () {
      this.pause()
      this.showHistoryLog = false
    },
    deviceStyl (item: any) {
      if (this.selectedDeviceType === 'elec_alarm') {
        if (item.online_status !== '1') {
          return {
            borderColor: '#a0a0a1'
          }
        }

        if (item.transient_short_circuit_fault === '1' ||
            item.electric_leakage_fault === '1' ||
            item.arc_discharge_fault === '1' ||
            item.short_time_delay_short_circuit_fault === '1' ||
            item.overload_fault === '1' ||
            item.overvoltage_fault === '1' ||
            item.undervoltage_fault === '1' ||
            item.temperature_fault === '1') {
          return {
            borderColor: '#e50112'
          }
        }

        return {
          borderColor: '#04d7c4'
        }
      } else {
        if (item.online_status !== '1') {
          return {
            borderColor: '#a0a0a1'
          }
        }

        return {
          borderColor: '#04d7c4'
        }
      }
    },
    loadDevices: function () {
      if (this.selectedDeviceType === 'elec_alarm') {
        getDevicesApi({
          token: this.token,
          host_no: this.selectedHostNo,
          host_type: 'elec_alarm',
          service_center: this.selectedServiceCenter,
          service_center_level: this.selectedServiceCenterLevel,
          type: this.showDeviceType,
          page: this.page
        }).then((res: any) => {
          const devices = res.devices

          this.page = devices.current_page
          this.pageNum = devices.current_page
          this.total = devices.total
          this.lastPage = devices.last_page
          this.perPage = devices.perPage
          this.devices = devices.data
        })
      } else {
        getAlarmDevicesApi({
          token: this.token,
          host_no: this.selectedHostNo,
          host_type: this.selectedDeviceType,
          service_center: this.selectedServiceCenter,
          service_center_level: this.selectedServiceCenterLevel,
          type: this.showDeviceType,
          page: this.page
        }).then((res: any) => {
          const devices: any = res.devices

          this.page = devices.current_page
          this.pageNum = devices.current_page
          this.total = devices.total
          this.lastPage = devices.last_page
          this.perPage = devices.perPage
          this.devices = devices.data
        })
      }
    },
    loadDevices2: function () {
      if (this.selectedDeviceType === 'elec_alarm') {
        getDevicesApi({
          token: this.token,
          host_no: this.selectedHostNo,
          host_type: 'elec_alarm',
          service_center: this.selectedServiceCenter,
          service_center_level: this.selectedServiceCenterLevel,
          type: this.showDeviceType,
          page: this.page
        }).then((res: any) => {
          const devices = res.devices

          this.page = devices.current_page
          this.total = devices.total
          this.lastPage = devices.last_page
          this.perPage = devices.perPage
          this.devices = devices.data
        })
      } else {
        getAlarmDevicesApi({
          token: this.token,
          host_no: this.selectedHostNo,
          host_type: this.selectedDeviceType,
          service_center: this.selectedServiceCenter,
          service_center_level: this.selectedServiceCenterLevel,
          type: this.showDeviceType,
          page: this.page
        }).then((res: any) => {
          const devices: any = res.devices

          this.page = devices.current_page
          this.total = devices.total
          this.lastPage = devices.last_page
          this.perPage = devices.perPage
          this.devices = devices.data
        })
      }
    },
    gotoFirstPage: function () {
      if (this.page !== 1) {
        this.page = 1

        this.loadDevices()
      }
    },
    gotoPrevPage: function () {
      if (this.page > 1) {
        this.page -= 1

        this.loadDevices()
      }
    },
    gotoNextPage: function () {
      if (this.page < this.lastPage) {
        this.page += 1

        this.loadDevices()
      }
    },
    gotoLastPage: function () {
      if (this.page !== this.lastPage) {
        this.page = this.lastPage

        this.loadDevices()
      }
    },
    gotoPage: function () {
      if (this.pageNum > this.lastPage) {
        this.pageNum = this.lastPage
      }

      if (this.pageNum < 1) {
        this.pageNum = 1
      }

      if (this.pageNum !== this.page) {
        this.page = this.pageNum

        this.loadDevices()
      }
    },
    switchDeviceType (type: string) {
      if (this.showDeviceType !== type) {
        this.showDeviceType = type

        this.loadDevices()
      }
    },
    checkDeviceStatus (item: any) {
      return item.transient_short_circuit_alarm === '1' ||
          item.electric_leakage_alarm === '1' ||
          item.arc_discharge_alarm === '1' ||
          item.short_time_delay_short_circuit_alarm === '1' ||
          item.overload_alarm === '1' ||
          item.overvoltage_alarm === '1' ||
          item.undervoltage_alarm === '1' ||
          item.temperature_alarm === '1' ||
          item.transient_short_circuit_fault === '1' ||
          item.electric_leakage_fault === '1' ||
          item.arc_discharge_fault === '1' ||
          item.short_time_delay_short_circuit_fault === '1' ||
          item.overload_fault === '1' ||
          item.overvoltage_fault === '1' ||
          item.undervoltage_fault === '1' ||
          item.temperature_fault === '1'
    },
    deviceItemClicked (item: any) {
      this.activeItem = item

      this.activeTab = 'device_status'
    },
    getOfflineDevices () {
      getOfflineDevicesApi({
        token: this.token,
        host_type: this.selectedDeviceType,
        page: this.offlineDevicePage
      }).then((res: any) => {
        console.log(res)
        this.offlineDeviceData = this.offlineDeviceData.concat(res.devices.data)
        this.offlineDeviceLastPage = res.devices.last_page
      })
    },
    getBrokenDevices () {
      getBrokenDevicesApi({
        token: this.token,
        host_type: this.selectedDeviceType,
        page: this.brokenDevicePage
      }).then((res: any) => {
        this.brokenDeviceData = this.brokenDeviceData.concat(res.devices.data)
        this.brokenDeviceLastPage = res.devices.last_page
      })
    },
    getTodayAlarm () {
      loadTodayAlarmApi({
        token: this.token,
        host_type: this.selectedDeviceType,
        page: this.todayAlarmPage
      }).then((res: any) => {
        this.todayAlarmData = this.todayAlarmData.concat(res.logs)
        this.todayAlarmLastPage = res.last_page
      })
    },
    loadMoreOfflineDevices() {
      if (this.offlineDevicePage < this.offlineDeviceLastPage) {
        this.offlineDevicePage++

        this.getOfflineDevices()
      }
    },
    loadMoreBrokenDevices() {
      if (this.brokenDevicePage < this.brokenDeviceLastPage) {
        this.brokenDevicePage++

        this.getBrokenDevices()
      }
    },
    loadMoreTodayAlarm() {
      if (this.todayAlarmPage < this.todayAlarmLastPage) {
        this.todayAlarmPage++

        this.getTodayAlarm()
      }
    },
    loadMoreAlarm() {
      if (this.alarmPage < this.alarmLastPage) {
        this.alarmPage++

        this.loadAlarmLogs()
      }
    },
    showOfflineDevice() {
      this.offlineDeviceData = []
      this.offlineDevicePage = 1

      this.getOfflineDevices()

      this.hideAlarmTables()

      this.showOfflineDeviceTable = true
    },
    showBrokenDevice() {
      this.brokenDeviceData = []
      this.brokenDevicePage = 1

      this.getBrokenDevices()

      this.hideAlarmTables()

      this.showBrokenDeviceTable = true
    },
    showTodayAlarm() {
      this.todayAlarmData = []
      this.todayAlarmPage = 1

      this.getTodayAlarm()

      this.hideAlarmTables()

      this.showTodayAlarmTable = true
    },
    showUntreatedAlarm() {
      this.loadMonthAlarmLogs('untreated')
    },
    showHandlingAlarm() {
      this.loadMonthAlarmLogs('handling')
    },
    showFinishedAlarm() {
      this.loadMonthAlarmLogs('finished')
    },
    loadMonthAlarmLogs(type: string) {
      this.alarmData = []
      this.alarmPage = 1
      this.alarmType = type

      this.loadAlarmLogs()

      this.hideAlarmTables()

      this.showAlarmInfoTable = true
    },
    monthAlarmAnaliseEnter() {
      if (this.monthAlarmTimeout !== null) {
        clearTimeout(this.monthAlarmTimeout)
      }
    },
    monthAlarmAnaliseLeave() {
      const self = this

      if (this.monthAlarmTimeout !== null) {
        clearTimeout(this.monthAlarmTimeout)
      }

      this.monthAlarmTimeout = setTimeout(function() {
        // self.loadMonthAlarmAnaliseChart()
        const options = self.monthAlarmAnaliseChart.getOption()

        options.legend[0].selected = {
          '设备离线': true,
          '设备报警': true,
          '设备故障': true
        }

        self.monthAlarmAnaliseChart.setOption(options, true)
      }, 10000)
    },
    deviceIncreaseEnter() {
      if (this.deviceIncreaseTimeout !== null) {
        clearTimeout(this.deviceIncreaseTimeout)
      }
    },
    deviceIncreaseLeave() {
      const self = this

      if (this.deviceIncreaseTimeout !== null) {
        clearTimeout(this.deviceIncreaseTimeout)
      }

      this.deviceIncreaseTimeout = setTimeout(function() {
        // self.loadDeviceIncreaseChart()
        const options = self.deviceIncreaseChart.getOption()

        options.legend[0].selected = {
          '设备新增' : true,
          '设备自管' : true,
          '设备托管' : true,
          '设备退网' : true
        }

        self.deviceIncreaseChart.setOption(options)
      }, 10000)
    },
    signalLevelEnter() {
      if (this.signalLevelTimeout !== null) {
        clearTimeout(this.signalLevelTimeout)
      }
    },
    signalLevelLeave() {
      const self = this

      if (this.signalLevelTimeout !== null) {
        clearTimeout(this.signalLevelTimeout)
      }

      this.signalLevelTimeout = setTimeout(function() {
        // self.loadSignalLevelChart()
        const options = self.signalLevelChart.getOption()

        options.legend[0].selected = {
          '信号强' : true,
          '信号中' : true,
          '信号弱' : true,
          '无信号' : true
        }

        self.signalLevelChart.setOption(options)
      }, 10000)
    },
    monthAlarmEnter() {
      if (this.monthAlarmTimeout !== null) {
        clearTimeout(this.monthAlarmTimeout)
      }
    },
    monthAlarmLeave() {
      const self = this

      if (this.monthAlarmTimeout !== null) {
        clearTimeout(this.monthAlarmTimeout)
      }

      this.monthAlarmTimeout = setTimeout(function() {
        self.initMonth()
        self.loadAlarmHandleRecord()
      }, 10000)
    },

    play: function (url: string) {
      console.log('3054 ::::: ', url)
      // eslint-disable-next-line no-undef
      webrtcPlayer = new ZLMRTCClient.Endpoint({
        element: document.getElementById('firstVideo'),// video 标签
        debug: true,// 是否打印日志
        zlmsdpUrl: url,//流地址
        simulecast: false,
        useCamera: false,
        audioEnable: true,
        videoEnable: true,
        recvOnly: true,
        usedatachannel: false,
      })

      // eslint-disable-next-line no-undef
      webrtcPlayer.on(ZLMRTCClient.Events.WEBRTC_ICE_CANDIDATE_ERROR,(e: any)=>{// ICE 协商出错
        console.error('ICE 协商出错')
        this.eventcallbacK("ICE ERROR", "ICE 协商出错")
      });

      // eslint-disable-next-line no-undef
      webrtcPlayer.on(ZLMRTCClient.Events.WEBRTC_ON_REMOTE_STREAMS,(e: any)=>{//获取到了远端流，可以播放
        console.log('播放成功',e.streams)
        this.eventcallbacK("playing", "播放成功")
      });

      // eslint-disable-next-line no-undef
      webrtcPlayer.on(ZLMRTCClient.Events.WEBRTC_OFFER_ANWSER_EXCHANGE_FAILED,(e: any)=>{// offer anwser 交换失败
        console.error('offer anwser 交换失败',e)
        this.eventcallbacK("OFFER ANSWER ERROR ", "offer anwser 交换失败")
        if (e.code ==-400 && e.msg=="流不存在"){
          console.log("流不存在")
          this.timer = setTimeout(()=>{
            this.webrtcPlayer.close();
            this.play(url)
          }, 100)

        }
      });

      // eslint-disable-next-line no-undef
      webrtcPlayer.on(ZLMRTCClient.Events.WEBRTC_ON_LOCAL_STREAM,(s: any)=>{// 获取到了本地流
        // document.getElementById('selfVideo').srcObject=s;
        this.eventcallbacK("LOCAL STREAM", "获取到了本地流")
      });

    },
    pause: function () {
      if (webrtcPlayer != null) {
        webrtcPlayer.close();
        webrtcPlayer = null;
      }
    },
    eventcallbacK: function(type: any, message: any) {
      console.log("player 事件回调")
      console.log(type)
      console.log(message)
    },
    treeNodeContextMenu: function (event: any, nodeId: string, node: any) {
      if (node.device_type === 'camera_alarm') {
        if (node.node_type === 'device') {
          event.preventDefault()

          this.contextEvent = event
          this.contextMenuNode = node
          this.contextMenuShow = true
          this.contextMenus = [
            {
              tag: 'preview',
              label: '预览视频'
            }
          ]
        }
      }

      if (this.adminType === 2) {
        if (node.node_type === 'user') {
          event.preventDefault()

          this.contextEvent = event
          this.contextMenuNode = node
          this.contextMenuShow = true

          let sourceData = JSON.parse(JSON.stringify(this.tempDeviceData))

          console.log('3629 :::: ', sourceData)

          const tmpMenu: any = []

          for (let i = 0; i < sourceData.regions.length; i++) {
            const item = sourceData.regions[i]

            if (item.parent_id === node.street) {
              tmpMenu.push({
                tag: 'switch_region',
                region_id: item.id,
                label: '移到 ' + item.label
              })
            }
          }

          this.contextMenus = tmpMenu
        }
      }
    },
    contextMenuEnter: function () {
      this.contextMenuCovered = true
    },
    contextMenuLeave: function () {
      this.contextMenuCovered = false
    },
    contextMenuClick: function (item: any) {
      this.contextMenuShow = false

      const tag: any = item.tag

      if (tag === 'preview') {
        this.saveCameraDevice = this.contextMenuNode
      } else if (tag === 'switch_region') {
        console.log('3660 :::: ', item, this.contextMenuNode)
        const user_no: string = this.contextMenuNode.host_no

        switchZoneApi({
          token: this.token,
          host_type: this.selectedDeviceType,
          host_no: user_no,
          zone_id: item.region_id
        }).then((res: any) => {
          for (let i = 0; i < this.tempDeviceData.users.length; i++) {
            if (this.tempDeviceData.users[i].host_no === user_no) {
              this.tempDeviceData.users[i].zone = item.region_id

              this.initRegionDeviceTreeData(JSON.parse(JSON.stringify(this.tempDeviceData)), this.filterText)

              break
            }
          }
        })
      }
    }
  }
})
export default class Main extends Vue {}
